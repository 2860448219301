<template>
  <main>
    <img class="logo" src="./assets/logo_with_name.png"/>
    <section>
      <img src="./assets/app_icon.png"/>
      <article v-if="showInstallPromotion">
        <button @click="triggerInstallPrompt">INSTALLEER NBB APP</button>
      </article>
      <article v-else>
        <EnterCode/>
      </article>
    </section>
  </main>
</template>

<script setup>
import EnterCode from './components/EnterCode.vue'
import { defineComponent } from 'vue'
import { usePWA } from './composables/pwa'

defineComponent({
  components: {
    EnterCode
  }
})

const { showInstallPromotion, triggerInstallPrompt } = usePWA()
</script>

<style>
html {
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  height: 100%;
  background-image: url('./assets/background_mobile.jpg');
  background-size: cover;
}

main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

img.logo {
  width: 300px;
  max-width: calc(100% - 40px);
  margin-top: 40px;
}

section {
  position: relative;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  border-radius: 12px;
  background-color: rgba(255, 255, 255);
  display: flex;
  gap: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
  width: 260px;
  height: 160px;
  max-width: 100%;
  margin-bottom: 40px;

  img {
    position: absolute;
    width: 160px;
    top: -120px;
  }

  article {
    display: flex;
    flex-direction: column;
    gap: 12px;

    input[type=text] {
      width: 100%;
      padding: 12px 20px;
      margin: 8px 0;
      box-sizing: border-box;
    }
  }
}

h1 {
  margin: 0;
  color: #102142;
  text-align: center;
}

button {
  background-color: #e75828;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;

  &:hover {
    cursor: pointer;
    background-color: #ee8a69;
  }
}
</style>
