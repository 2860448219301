import { ref, onMounted } from 'vue'

export function usePWA() {
  const showInstallPromotion = ref(false);
  const deferredPrompt = ref(null);

  onMounted(() => {
    console.log('PWA')
    window.addEventListener('beforeinstallprompt', (e) => {
      console.log(e)
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault()
      // Stash the event so it can be triggered later.
      deferredPrompt.value = e
      // Update UI notify the user they can install the PWA
      showInstallPromotion.value = true
    })

    window.addEventListener('appinstalled', () => {
      showInstallPromotion.value = false
      // Clear the deferredPrompt so it can be garbage collected
      deferredPrompt.value = null
    })
  })

  function triggerInstallPrompt() {
    console.log('deferred prompt: ', deferredPrompt.value)
    if (deferredPrompt.value) {
      deferredPrompt.value.prompt();
    }
  }

  return { showInstallPromotion, triggerInstallPrompt }
}